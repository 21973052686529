import React, { useState } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';

import SmartFeedFiltersComponent from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedWithFilters_WT';
import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';
import BasicSlider from './BasicSlider';

export default function HomepagePage_Content(props) {
  const {
    pageContext: { config, contentDataData, contentMetaData },
  } = props;

  const siteConfig = config;

  const [vacancyLoading, setVacancyLoading] = useState(true);

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      title={`${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName || ''
      } Careers`}
      description={`Careers at ${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
      }`}
    >
      <div className="main-wpr">
        <div className="banner page home-banner col-md-12" id="banner">
          {contentDataData[0] &&
            contentDataData[0].children[0] &&
            contentDataData[0].children[0].children &&
            contentDataData[0].children[0].children.length > 0 && (
              <BasicSlider
                onBeforeChangeFunction={(type, previous, next) => {
                  return null;
                }}
                height="539px"
                position="relative"
                backgroundSize="auto"
                shouldDisplayButtons={false}
                onBeforeChangeFunction={(type, next) => {
                  return null;
                }}
                onBeforeChangeFunction={(type, next) => {
                  return null;
                }}
                slides={contentDataData[0].children[0].children}
                OverlayContainer={
                  <div className="banner-content">
                    {contentDataData[0] && contentDataData[0].children && (
                      <div className="container">
                        {contentDataData[0].children[1] &&
                          contentDataData[0].children[1].value && (
                            <h1 className="banner-title">
                              {contentDataData[0].children[1].value}
                            </h1>
                          )}
                        {contentDataData[0].children[2] &&
                          contentDataData[0].children[2].value && (
                            <h2 className="banner-subtitle">
                              {contentDataData[0].children[2].value}
                            </h2>
                          )}
                        {contentDataData[0].children[3] &&
                          contentDataData[0].children[3].value && (
                            <p className="banner-paragraph">
                              {contentDataData[0].children[3].value}
                            </p>
                          )}
                      </div>
                    )}
                  </div>
                }
              />
            )}
        </div>

        {contentDataData &&
          contentDataData[1] &&
          contentDataData[1].children &&
          contentDataData[1].children[0] && (
            <div className="col-md-12" id="welcome-homepage">
              <div className="container">
                <WigLoader
                  weducWebsitesID={config.weducWebsitesID}
                  weducContainer={config.weducContainerID}
                  weducEntity={config.weducEntityID}
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="1"
                  contentDataChild="0"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                  imageFormatting={
                    process.env.IMAGE_FORMATTING_SECRET ||
                    process.env.GATSBY_IMAGE_FORMATTING_SECRET
                  }
                />
              </div>
            </div>
          )}

        <div className="col-md-12" id="vacancies">
          <div className="live-vacancies" style={{ margin: '0' }}>
            {!props.vacanciesRouteLoading &&
              (siteConfig.siteConfig || props.smartfeedCompanyData) &&
              props.apiKey &&
              props.groupOrIdParam && (
                <>
                  <SmartFeedFiltersComponent
                    siteConfig={siteConfig}
                    setVacancyLoading={setVacancyLoading}
                    vacancyLoading={vacancyLoading}
                    apiKey={siteConfig.apiKey || props.apiKey}
                    group={
                      siteConfig.group
                        ? siteConfig.group
                        : props.groupOrIdParam === 'id'
                        ? false
                        : props.groupOrIdParam === 'group'
                        ? true
                        : false
                    }
                    gdprKey={
                      siteConfig.gdprKey || siteConfig.apiKey || props.apiKey
                    }
                    smartfeedCompanyData={
                      siteConfig.smartfeedCompanyData ||
                      props.smartfeedCompanyData
                    }
                    CompanyGroupName={
                      siteConfig.companyName ||
                      props.smartfeedCompanyData.CompanyName
                    }
                    OptInLink={
                      siteConfig.companyCareersSite
                        ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                        : siteConfig.OptInLink ||
                          `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/optin/?OptInID=`
                    }
                    UnsubscribeLink={
                      siteConfig.companyCareersSite
                        ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                        : siteConfig.UnsubscribeLink ||
                          `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/unsubscribe/?UnsubscribeID=`
                    }
                    VacancyPageBaseURL={
                      siteConfig.companyCareersSite
                        ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                        : siteConfig.VacancyPageBaseURL ||
                          `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/vacancy/`
                    }
                    ReturnToSearchURL={
                      siteConfig.companyCareersSite
                        ? `${siteConfig.careersSiteDomain}/vacancies/`
                        : siteConfig.ReturnToSearchURL ||
                          `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/`
                    }
                    contentDataData={props.contentDataData || {}}
                    location={siteConfig.showLocationJA}
                    region={siteConfig.showRegionJA}
                    category={siteConfig.showCategoryJA}
                    industry={siteConfig.showIndustryJA}
                    jobType={siteConfig.showJobTypeJA}
                    jobTime={siteConfig.showJobTimeJA}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
